import { APP_INITIALIZER, Host, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { MenuModule } from '@progress/kendo-angular-menu';

import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogModule } from '@digic/dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { LayoutModule as dAppLayoutModule } from '@digic/layout'
import { LayoutComponent } from '@digic/layout';
import { DefaultEmployeeService, SharedModule, EmployeeServiceBase, ENVIRONMENTER, LocalizationServiceBase, NotificationServiceBase, HttpLoaderFactory, WinAuthInterceptor, HttpErrorInterceptor, DefaultNotificationService, DefaultLocalizationService, AuthenticationService, initializeKeycloak, HealthcheckService } from '@digic/shared'
import { ENVIRONMENTER as LayoutEnvironmenter} from '@digic/layout';
import { ENVIRONMENTER as NotificationsEnvironmenter} from '@digic/notifications';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CdkTreeModule } from '@angular/cdk/tree';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SvgLoaderModule } from '@digic/svg-loader';
import { HostsModule } from '@digic/hosts';
import { KeycloakService } from 'keycloak-angular';
import { ReactiveFormsModule } from '@angular/forms';

// function initializeKeycloak(keycloak: KeycloakService, authenticationService:AuthenticationService) {
//   return async () => initializeKeycloak(keycloak, authenticationService);
// }

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    CdkTreeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    
    //Kendo modules
    MenuModule,
    ButtonsModule,
    NotificationModule,
    InputsModule,
    TooltipModule,
    DialogsModule,
    DropDownsModule,

    DialogModule,
    LayoutModule,
    SharedModule,
    SvgLoaderModule,
    HostsModule,
    
    dAppLayoutModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "hu",
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: WinAuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: NotificationServiceBase, useClass: DefaultNotificationService },
    { provide: LocalizationServiceBase, useClass: DefaultLocalizationService },
    { provide: EmployeeServiceBase, useClass: DefaultEmployeeService },
    { provide: ENVIRONMENTER, useValue: environment },
    { provide: LayoutEnvironmenter, useValue: environment },
    { provide: NotificationsEnvironmenter, useValue: environment },
    // ResourceGroupService,    
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService, HealthcheckService]
    }

  ],
  bootstrap: [LayoutComponent],
})
export class AppModule { }
