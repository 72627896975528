import { Component, HostListener, Injector, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ResponsiveService } from "@digic/layout";
import { AppComponentBase, ApplicationService, EmployeeServiceBase, UserSettingsService, WriteUs } from "@digic/shared";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends AppComponentBase implements OnInit {
  public get maintenanceDate(): Date {
    return this.applicationService.maintenanceDate;
  }

  public get windowHeight(): number {
    return this.responsiveService.windowHeight;
  }

  public get windowWidth(): number {
    return this.responsiveService.windowWidth;
  }

  public get xl(): number {
    return this.responsiveService.xl;
  }

  public get md(): number {
    return this.responsiveService.md;
  }

  public get hosts() {
    return this.userSettingsService.myHosts;
  }

  public user: any = [];
  public langImgPath: string;
  public checked: boolean;
  public listItems: Array<string> = ["bug", "észrevétel", "fejlesztési igény"];
  public writeUsForm: UntypedFormGroup;
  public currentDate = new Date();
  public isMaintenanceIncoming: boolean;
  public isUnderMaintenance: boolean;
  public environment = environment;
  public avatar: any;

  constructor(
    injector: Injector,
    private employeeServiceBase: EmployeeServiceBase,
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getAdditionalUserInfo();
    this.createWriteUsForm();
    this.getMaintenanceDate();
    this.userSettingsService.getHosts();
    //this.userSettingsService.myHosts = this.userSettingsService.dummyhosts;

    this.employeeServiceBase.getAvatar(
      this.authenticationService.currentUserAccount.dpc_handle
    ).then((image) => {
      this.avatar = image; 
    });

    
  }

  /**
   * onSubmit
   */
  public onSubmit() {
    let node: WriteUs = {
      name:
        this.writeUsForm.value.type + " - " + this.writeUsForm.value.subject,
      comment: this.writeUsForm.value.message,
    };
    this.postWriteUs(node);
    this.writeUsForm.reset();
  }

  /**
   * getAdditionalUserInfo
   */
  public getAdditionalUserInfo() {
    this.employeeServiceBase
      .getUserByHandle(this.authenticationService.currentUserAccount.dpc_handle)
      .subscribe((rslt) => {
        this.user = rslt;
      });
  }

  public getMaintenanceDate() {
    this.applicationService.maintenance().subscribe((rslt) => {
      this.isMaintenanceIncoming =
        this.maintenanceDate &&
        this.currentDate.getTime() < this.maintenanceDate.getTime();
      this.isUnderMaintenance =
        this.maintenanceDate &&
        this.maintenanceDate.getTime() + 3600 * 1000 >
          this.currentDate.getTime() &&
        !this.isMaintenanceIncoming;
    });
  }

  public changeLocale(locale: string): void {
    this.localizationService.changeLocale(locale);
  }

  public getLangs(): string[] {
    return this.localizationService.getLangs();
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  /**
   ********************* PRIVATE AREA -> UNAUTHORIZED ACCESS ATTEMPT IS STRICKTLY PROHIBITED *********************
   */

  private postWriteUs(node: WriteUs) {
    this.applicationService.writeUs(node).subscribe(
      (rslt) => {
        this.translate
          .get("dapp.dashboard.write-us.notification.text")
          .subscribe((localizedText) => {
            this.notificationService.show(
              localizedText,
              "none",
              "Ticket sent!",
              this.router.url,
              "dproject-app/src/app/components/dashboard/dashboard.component.ts/postWriteUs()"
            );
          });
      },
      (error) => {
        console.log(error);
        this.translate
          .get("dapp.dashboard.write-us.notification.error")
          .subscribe((localizedText) => {
            this.notificationService.show(
              localizedText,
              "error",
              `Error Message: ${error.message}`,
              this.router.url,
              "dproject-app/src/app/components/dashboard/dashboard.component.ts/postWriteUs()"
            );
          });
      }
    );
  }

  private createWriteUsForm() {
    this.writeUsForm = this.formBuilder.group({
      type: "",
      subject: "",
      message: "",
    });
  }
}
