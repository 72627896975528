import { ServiceProxy } from "./service-proxy.model";

import packageJson from "../../package.json";
export const DPROJECT_SERVER_BASE_URL = "https://api.digicgroup.com/";
export const SOYAL_SERVER_BASE_URL = "https://svblue.digicpictures.local/diceApi/";
export const DAPP_URL = "https://dapp.digicgroup.com/";
export const LOGIN_URL = "https://login.digicpictures.local/";
export const DAPP_DASHBOARD_URL = "https://dashboard.digicgroup.com/";
export const TRAININGROOM_URL = "https://trainingroom.digicpictures.local/";
export const INFRASTRUCTURE_URL = "https://infrastructure.digicpictures.local/";
export const HR_URL = "https://hr.digicpictures.local/";
export const DAPP_ADMIN_URL = "https://dappadmin.digicpictures.local/";
export const NOTIFICATIONS_SERVICE = DPROJECT_SERVER_BASE_URL;

export const environment = {
  version: packageJson.version,
  production: true,
  baseHREF: "/",
  apiEndpoints: ServiceProxy.getEndPoints(DPROJECT_SERVER_BASE_URL, SOYAL_SERVER_BASE_URL),
  keycloakConfig: {
    url: "https://id.digicgroup.com",
    realm: "Digic",
    clientId: "dapp",
  },
  DPROJECT_SERVER_BASE_URL: DPROJECT_SERVER_BASE_URL,
  DAPP_URL: DAPP_URL,
  SOYAL_SERVER_BASE_URL: SOYAL_SERVER_BASE_URL,
  DAPP_DASHBOARD_URL: DAPP_DASHBOARD_URL,
  LOGIN_URL: LOGIN_URL,
  TRAININGROOM_URL: TRAININGROOM_URL,
  INFRASTRUCTURE_URL: INFRASTRUCTURE_URL,
  HR_URL: HR_URL,
  DAPP_ADMIN_URL: DAPP_ADMIN_URL,
  NOTIFICATIONS_SERVICE: NOTIFICATIONS_SERVICE,
};
