<div class="container-fluid p-0 m-0">
  <div class="row h-100 p-0 m-0">
    <div class="col-3 h-100 p-0 m-0" *ngIf="windowWidth >= xl">
      <div class="dashboard-write-us coming-soon h-100">
        <h5 class="dashboard-write-us-title">
          {{ "dapp.dashboard.write-us.title" | translate }}
        </h5>
        <p>
          {{ "dapp.dashboard.write-us.text" | translate }}
        </p>
        <form [formGroup]="writeUsForm" (ngSubmit)="onSubmit()">
          <div class="dashboard-write-us-type-selector">
            <select formControlName="type">
              <option value="">
                {{ "dapp.dashboard.write-us.type" | translate }}
              </option>
              <option *ngFor="let item of listItems" value="{{ item | titlecase }}">
                {{ item | titlecase }}
              </option>
            </select>
          </div>
          <input type="text" class="dashboard-write-us-subject"
            placeholder="{{ 'dapp.dashboard.write-us.subject' | translate }}" formControlName="subject" />
          <div class="dashboard-write-us-textarea-holder">
            <textarea class="dashboard-write-us-textarea"
              placeholder="{{ 'dapp.dashboard.write-us.message' | translate }}" formControlName="message"
              style="resize: none"></textarea>
          </div>
          <div class="dashboard-write-us-button-holder">
            <button kendoButton type="submit" class="dashboard-write-us-button" themeColor="primary" disabled>
              {{ "dapp.dashboard.write-us.button" | translate }}
            </button>
          </div>
        </form>
        <span class="spacer"></span>
        <img class="boi-with-mail" *ngIf="windowHeight > md" src="../../assets/images/dashboard/levelesfaszika.png"
          alt="" />
      </div>
    </div>

    <div class="col-xl-6 col-12 h-100 p-0 m-0">
      <div class="dashboard-main h-100">
        <div class="row">
          <div class="col">
            <div class="welcome-header row mx-0" *ngIf="windowWidth >= xl">
              <div class="col">
                <h3>
                  {{ "dapp.dashboard.main.header.title" | translate }},
                  {{ user.dpc_nickname }}!
                </h3>
              </div>
              <div class="col">
                <img class="boi" src="../../assets/images/dashboard/faszika.png" alt="" />
                <img class="flower" src="../../assets/images/dashboard/flower.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="hosts.length <= 2 && windowWidth < xl">
          <div class="row">
            <div class="col-sm-12">
              <div class="box">
                <div class="dashboard-profile-hosts">
                  <div class="dashboard-profile-hosts-title">
                    <span class="dashboard-profile-hosts-title">{{ "dapp.dashboard.profile.hosts.title" | translate
                      }}:</span>
                  </div>
                </div>
                <app-hosts></app-hosts>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="box">
              <span [appSvgLoader]="'hr-modul'" [fillA]="'#1EA4D9'" class="icon"></span>
              <h5>{{ "dapp.dashboard.main.feedback.title" | translate }}</h5>
              <p>
                {{ "dapp.dashboard.main.feedback.text" | translate }}
              </p>
              <span class="spacer"></span>
              <a [attr.href]="environment.HR_URL + 'hr/dfeedback'">
                <button kendoButton themeColor="primary">
                  {{ "dapp.dashboard.main.feedback.button" | translate }}
                </button>
              </a>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="box">
              <span [appSvgLoader]="'ter-big'" [fillA]="'#1EA4D9'" class="icon ter-icon"></span>
              <h5>{{ "dapp.dashboard.main.pes.title" | translate }}</h5>
              <p>
                {{ "dapp.dashboard.main.pes.text" | translate }}
              </p>
              <span class="spacer"></span>
              <a [attr.href]="environment.HR_URL + 'hr/ter/personal'">
                <button kendoButton themeColor="primary">
                  {{ "dapp.dashboard.main.pes.button" | translate }}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="box-landscape row m-0">
              <div class="col me-auto">
                <h5>
                  {{ "dapp.dashboard.main.host-management.title" | translate }}
                </h5>
                <p>
                  {{ "dapp.dashboard.main.host-management.text" | translate }}
                </p>
              </div>
              <div class="col-12 col-md-3">
                <a [attr.href]="environment.INFRASTRUCTURE_URL + 'host-management'">
                  <button kendoButton themeColor="primary">
                    {{ "dapp.dashboard.main.host-management.button" | translate }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PROFILE -->
    <div class="col-3 h-100 p-0 m-0" *ngIf="windowWidth >= xl">
      <div class="dashboard-profile h-100">
        <div class="alert alert-warning fade show mb-5 text-start" role="alert" *ngIf="isMaintenanceIncoming">
          {{ "dapp.dashboard.main.maintenance-incoming" | translate }}
          <strong>{{ maintenanceDate | date : "medium" }}</strong>
        </div>
        <div class="alert alert-warning fade show mb-5 text-start" role="alert" *ngIf="isUnderMaintenance">
          {{ "dapp.dashboard.main.under-maintenance" | translate }}
        </div>
        <div class="dashboard-profile-avatar-holder">
          <img *ngIf="avatar" [src]="avatar" class="dashboard-profile-avatar" />
        </div>
        <!-- NAME -->
        <div class="dashboard-profile-name-holder">
          <span class="dashboard-profile-name">{{ user.name }}</span>
          <span class="dashboard-profile-nickname">@{{ user.dpc_nickname }}</span>
        </div>
        <!-- LANGUAGE -->
        <div class="dashboard-profile-language-selector-holder">
          <span class="dashboard-profile-language-selector-title">{{ "dapp.dashboard.profile.language" | translate
            }}:</span>
          <!-- <select #langSelect (change)="translate.use(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
          </select> -->
          <select #langSelect (change)="changeLocale(langSelect.value)">
            <option *ngFor="let lang of getLangs()" [value]="lang" [selected]="lang === this.currentLocale">
              {{ lang }}
            </option>
          </select>
        </div>
        <!-- HOSTS -->
        <ng-container *ngIf="hosts.length <= 2">
          <div class="dashboard-profile-hosts">
            <div class="dashboard-profile-hosts-title">
              <span class="dashboard-profile-hosts-title">{{ "dapp.dashboard.profile.hosts.title" | translate }}:</span>
            </div>
          </div>
          <app-hosts></app-hosts>
        </ng-container>
        <span class="spacer"></span>
        <!-- SIGN OUT -->
        <div class="dashboard-profile-sign-out-holder">
          <button kendoButton class="dashboard-profile-sign-out-button" themeColor="primary" (click)="logout()">
            {{ "dapp.dashboard.profile.sign-out" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>