export class ServiceProxy {
  public static getEndPoints(
    dprojectServerUrl: string,
    soyalServerUrl: string
  ): any {
    const DPROJECT_SERVICE_BASE_URL = `${dprojectServerUrl}api/`;
    const DAPP_SERVICE_BASE_URL = `${dprojectServerUrl}dapp/api/`;
    const CLOUD_ATLAS_SERVICE_BASE_URL = `${dprojectServerUrl}cloudatlas/api/`;
    const SOYAL_SERVICE_BASE_URL = `${soyalServerUrl}api/`;

    return {
      dProjectBase: `${DPROJECT_SERVICE_BASE_URL}`,
      dApp: {
        ActionLogAPI: `${DAPP_SERVICE_BASE_URL}v1/ActionLog`,
        LocalizationAPI: `${DAPP_SERVICE_BASE_URL}Localization`,
        PermissionsAPI: `${DAPP_SERVICE_BASE_URL}Permissions`,
        StatisticsAPI: `${DAPP_SERVICE_BASE_URL}Statistics`,
        EvaluationAPI: `${DAPP_SERVICE_BASE_URL}v1/Evaluation`,
        FeedbacksAPI: `${DAPP_SERVICE_BASE_URL}Feedbacks`,
        FeedbackRequestsAPI: `${DAPP_SERVICE_BASE_URL}Requests`,
        QuestionFormsAPI: `${DAPP_SERVICE_BASE_URL}questionforms`,
        UsersAPI: `${DAPP_SERVICE_BASE_URL}Users`,
        PlatformAPI: `${DAPP_SERVICE_BASE_URL}Platform`,
        WorkspacesAPI: `${DAPP_SERVICE_BASE_URL}v1/Workspaces`,
        PcReservationsAPI: `${DAPP_SERVICE_BASE_URL}v1/PcReservations`,
        WorkspaceImagesAPI: `${DAPP_SERVICE_BASE_URL}v1/workspaceimages`,
        projectfeedback: {
          hr: {
            ProjectFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/hr/ProjectFeedbacks`,
            DivisionFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/hr/DivisionFeedbacks`,
            MovieFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/hr/MovieFeedbacks`,
            ClientFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/hr/ClientFeedbacks`,
          },
          director: {
            OutgoingProjectFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/director/outgoing/ProjectFeedbacks`,
            IncomingProjectFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/director/incoming/ProjectFeedbacks`,
            DivisionFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/director/DivisionFeedbacks`,
            MovieFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/director/MovieFeedbacks`,
          },
          participant: {
            ProjectFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/participant/ProjectFeedbacks`,
            DivisionFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/participant/DivisionFeedbacks`,
            MovieFeedbacksAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/participant/MovieFeedbacks`,
          },
          QuestionsFormsAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/QuestionForms`,
          MovieCandidatesAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/MovieCandidates`,
          ParticipantAssignmentsAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/ParticipantAssignments`,
          ParticipantsAPI: `${DAPP_SERVICE_BASE_URL}v1/projectfeedback/Participants`,
        },
        TrainingRoomAPI: `${DAPP_SERVICE_BASE_URL}v1/TrainingRoom`,
        TranslationAPI: `${DAPP_SERVICE_BASE_URL}v1/Translation`,
        MovieVersion: `${DAPP_SERVICE_BASE_URL}v1/MovieVersion`,
      },
      dProject: {
        divisionDirector: {
          DivisionsAPI: `${DPROJECT_SERVICE_BASE_URL}v1/divisionDirector/Divisions`,
        },
        AuthenticationAPI: `${DPROJECT_SERVICE_BASE_URL}Authentication/`,
        TokenAPI: `${DPROJECT_SERVICE_BASE_URL}Token`,
        LocalizationAPI: `${DPROJECT_SERVICE_BASE_URL}Localization`,
        ResourcesAPI: `${DPROJECT_SERVICE_BASE_URL}Resources`,
        MoviesAPI: `${DPROJECT_SERVICE_BASE_URL}Movies`,
        StatusesAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Statuses`,
        CommentflowAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Commentflow`,
        NodesAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Nodes`,
        NotificationsAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Notifications`,
        CommentsAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Comments`,
        TopicsAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Topics`,
        ResourceGroupsAPI: `${DPROJECT_SERVICE_BASE_URL}v1/ResourceGroups`,
        MoviesAPIV1: `${DPROJECT_SERVICE_BASE_URL}v1/Movies`,
        PlaylistAPI: `${DPROJECT_SERVICE_BASE_URL}v1/Playlist`,
        TicketAPI: DPROJECT_SERVICE_BASE_URL + "Tickets/",
      },
      cloudAtlas: {
        RvSessionsAPI: `${CLOUD_ATLAS_SERVICE_BASE_URL}v1/Sessions`,
        IpRegistrantAPI: `${CLOUD_ATLAS_SERVICE_BASE_URL}v1/Ips`,
        StreamServerAccessLogsAPI: `${CLOUD_ATLAS_SERVICE_BASE_URL}v1/StreamServerAccessLogs`,
      },
      Soyal: {
        TokenAPI: SOYAL_SERVICE_BASE_URL + "Token",
        CardAPI: SOYAL_SERVICE_BASE_URL + "Card/",
        MessageAPI: SOYAL_SERVICE_BASE_URL + "Message/",
        EmployeeAPI: SOYAL_SERVICE_BASE_URL + "Employee/",
        AuthenticationAPI: SOYAL_SERVICE_BASE_URL + "Authentication/",
      },
    };
  }
}
